import React, { useState, useEffect, useRef, ChangeEvent, FormEvent,useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {getPresignedUrlforBranch} from '../services/preSignedUrlService';
import Swal from 'sweetalert2';
import keycloak from '../config/keycloakConfig'; 
import { createGymBranch, updateGymBranch } from '../services/gymBranchService';
import { saveGymBranchImages } from '../services/imageUploadService';
import OpeningHours from './OpeningHours';
import Spinner from './spinner';
import { getGymById } from '../services/gymBranchService';
import { getBranchImageById } from '../services/imageUploadService';
import { updateGymBranchImages } from '../services/imageUploadService';

// Define the structure of formData
interface  FormData {
  location: string;
  latitude: number | null;
  longitude: number | null;
  branchName: string;
  openingTime: string;
  closingTime: string;
  offDays: string[];
  holderName: string;
  accountNumber: string;
  bankName: string;
  branchBankName: string;
  swiftCode: string;
  logos: File[];
  gymtype: string;
  description: string;  // Change from `""` to `string`
  number: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};


interface Hour {
  day: string;
  isOpen: boolean;
  startTime: string;
  endTime: string;
}

const initialHours: Hour[] = [
  { day: 'Monday', isOpen: true, startTime: '', endTime: '' },
  { day: 'Tuesday', isOpen: true, startTime: '', endTime: '' },
  { day: 'Wednesday', isOpen: true, startTime: '', endTime: '' },
  { day: 'Thursday', isOpen: true, startTime: '', endTime: '' },
  { day: 'Friday', isOpen: true, startTime: '', endTime: '' },
  { day: 'Saturday', isOpen: true, startTime: '', endTime: '' },
  { day: 'Sunday', isOpen: true, startTime: '', endTime: '' },
];

// Define the structure of errors for form validation
interface FormErrors {
  branchName?: string;
  openingTime?: string;
  closingTime?: string;
  holderName?: string;
  accountNumber?: string;
  bankName?: string;
  branchBankName?: string;
  swiftCode?: string;
  location?: string;
  gymtype?:string;
}

interface LogoMetaData {
  id: number;
  url: string;
  name: string; 
}

// Declare google globally
declare global {
  interface Window {
    google: any;
  }
}
const AddBranch: React.FC = () => {
  const location = useLocation();
  const gymId = location.state?.gymId;
  const gymGroupId = location.state?.gymGroupId;
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null); // Use ref instead of querySelector
  const [openingHours, setOpeningHours] = useState<Hour[]>(initialHours);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    location: '',
    latitude: null,
    longitude: null,
    branchName: '',
    openingTime: '',
    closingTime: '',
    offDays: [],
    holderName: '',
    accountNumber: '',
    bankName: '',
    branchBankName: '',
    swiftCode: '',
    logos: [],
    gymtype:'',
    description: '', // Optional new description field     // New address fields
    number: '',
    street: '',
    city: '',
    state: '',
    country: '',
    postalCode: ''
  });

  const [logoMetaData, setLogoMetaData] = useState<LogoMetaData[]>([]);
  const [removedImageIds, setRemovedImageIds] = useState<number[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [success, setSuccess] = useState<boolean>(false);


  const autocompleteRef = useRef<HTMLInputElement>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);


  const validate = () => {
    let tempErrors: FormErrors = {};
    if (!formData.branchName) tempErrors.branchName = 'Branch Name is required';
    if (!formData.holderName) tempErrors.holderName = 'Account holder name is required';
    if (!formData.bankName) tempErrors.bankName = 'Bank Name is required';
    if (!formData.branchBankName) tempErrors.branchBankName = 'Branch Bank Name is required';
    if (!formData.swiftCode) tempErrors.swiftCode = 'Swift code is required';
    if (!formData.accountNumber) tempErrors.accountNumber = 'Account number is required';
    if (!formData.gymtype) tempErrors.gymtype = 'Gym type is required';

    setErrors(tempErrors);
    setIsFormValid(Object.keys(tempErrors).length === 0);
  };

  const handleChange = ( e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validate();
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
    validate();
  };

  // Fetch gym details and set initial form data
  interface GeoLocation {
    latitude: number;
    longitude: number;
  }
  
  useEffect(() => {
    const fetchGymDetails = async () => {
      if (gymId) {
        try {
          const gymDetails = await getGymById(gymId);
          console.log("Gym Details:", gymDetails); // Debugging log
  
          const geoLocation: GeoLocation | null = gymDetails.geoLocation
            ? typeof gymDetails.geoLocation === 'string'
              ? null // If it's a string, handle it separately
              : gymDetails.geoLocation
            : null;
  
          if (geoLocation) {
            const { latitude, longitude } = geoLocation;
  
            setFormData((prevData) => ({
              ...prevData,
              latitude,
              longitude,
              branchName: gymDetails.gymName || "",
              holderName: gymDetails.accountHolderName || "",
              accountNumber: gymDetails.accountNumber || "",
              bankName: gymDetails.bankName || "",
              branchBankName: gymDetails.branchBankName || "",
              swiftCode: gymDetails.swiftCode || "",
              gymtype: gymDetails.gymType || "",
              description: gymDetails.description || "",
              number: gymDetails.number || "",
              street: gymDetails.street || "",
              city: gymDetails.city || "",
              state: gymDetails.state || "",
              country: gymDetails.country || "",
              postalCode: gymDetails.postalCode || "",
            }));
          } else {
            console.error("geoLocation is not valid:", gymDetails.geoLocation);
          }
        } catch (error) {
          console.error("Error fetching gym details:", error);
        }
      }
    };
  
    fetchGymDetails();
  }, [gymId]);
  
  

  // Initialize map and update when latitude or longitude changes
  useEffect(() => {
    if (formData.latitude && formData.longitude) {
      const location = { lat: formData.latitude, lng: formData.longitude };
      
      // Initialize or update map instance
      if (!mapInstance.current) {
        mapInstance.current = new window.google.maps.Map(mapRef.current, {
          center: location,
          zoom: 12,
        });
      } else {
        mapInstance.current.setCenter(location);
      }

      // Add or update marker
      const marker = new window.google.maps.Marker({
        position: location,
        map: mapInstance.current,
      });

      // Reverse geocode to get address if location has changed
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === 'OK' && results[0]) {
          setFormData((prevData) => ({
            ...prevData,
            location: results[0].formatted_address,
          }));
        }
      });
      

      // Clean up old marker when location changes
      return () => {
        marker.setMap(null);
      };
    }
  }, [formData.latitude, formData.longitude]);

  // Initialize Google Places Autocomplete
  useEffect(() => {
    if (window.google) {
      // Initialize Autocomplete
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current!);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const location = place.geometry?.location;
        if (location) {
          setFormData((prevData) => ({
            ...prevData,
            location: place.formatted_address || '',
            latitude: location.lat(),
            longitude: location.lng(),
          }));

          const map = new window.google.maps.Map(mapRef.current!, {
            center: { lat: location.lat(), lng: location.lng() },
            zoom: 5,
          });

          new window.google.maps.Marker({
            position: { lat: location.lat(), lng: location.lng() },
            map: map,
          });
        }
      });

      // Initialize Map
      const map = new window.google.maps.Map(mapRef.current!, {
        center: { lat: 23.8859, lng: 45.0792 },
        zoom: 5,
      });

      // Add click event listener to the map
      map.addListener('click', (event: google.maps.MapMouseEvent) => {
        const clickedLocation = event.latLng;
        if (clickedLocation) {
          setFormData((prevData) => ({
            ...prevData,
            latitude: clickedLocation.lat(),
            longitude: clickedLocation.lng(),
          }));

          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: clickedLocation }, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            if (status === 'OK' && results[0]) {
              setFormData((prevData) => ({
                ...prevData,
                location: results[0].formatted_address || '',
              }));
            }
          });

          new window.google.maps.Marker({
            position: clickedLocation,
            map: map,
          });
        }
      });
    }
  }, []);

  const handleCancel = () => {
    navigate('/gym-branch');
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setFormData((prevData) => ({
      ...prevData,
      logos: [...prevData.logos, ...files],
    }));
    validate();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
// Handle image removal and track removed IDs
const handleRemoveImage = (index: number) => {
  const updatedLogos = formData.logos.filter((_, i) => i !== index);
  const updatedLogoMetaData = logoMetaData.filter((_, i) => i !== index);

  // Track removed ID if it exists in logoMetaData
  const removedId = logoMetaData[index]?.id;
  if (removedId) {
    setRemovedImageIds((prevIds) => {
      const updatedIds = [...prevIds, removedId];
      console.log("Removed IDs:", updatedIds);  // Log the removed IDs here
      return updatedIds;
    });
  }

  setFormData((prevData) => ({ ...prevData, logos: updatedLogos }));
  setLogoMetaData(updatedLogoMetaData);

  if (updatedLogos.length === 0) {
    closeModal();
  }
};


  const handleNextImage = () => {
    if (currentImageIndex < formData.logos.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };


  const cleanPresignedUrls = (urls: any): string[] => {
    if (Array.isArray(urls)) {
      // Filter out empty strings and clean each URL in the array
      return urls
          .filter(url => typeof url === 'string' && url.trim() !== '') // Remove empty or whitespace-only strings
          .map(url => url.startsWith('data:') ? url.replace('data:', '') : url);
    } else if (typeof urls === 'string') {
      // Split the string into an array by whitespace, clean, and filter out empty values
      return urls
          .split(/\s+/)
          .filter(url => url.trim() !== '') // Remove empty or whitespace-only strings
          .map(url => url.startsWith('data:') ? url.replace('data:', '') : url);
    } else {
      console.error('Expected a string or an array but received:', urls);
      return [];
    }
  };

// Function to upload images to the server
const uploadImages = async (files: File[], presignedUrls: string[]) => {
  const uploadPromises = files.map((file, index) => {
    const formData = new FormData();
    formData.append('file', file); // Adjust based on your API requirements

    return fetch(presignedUrls[index], {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type, // Set the Content-Type header to the file's MIME type
      },
    });    
  });

  try {
    const responses = await Promise.all(uploadPromises);
    
    // Check if all uploads were successful
    responses.forEach((response) => {
      if (!response.ok) {
        throw new Error('Failed to upload one or more images');
      }
    });

    // Return the URLs of the uploaded images (you might need to adjust this part)
    return presignedUrls;
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    
    Swal.fire({
      icon: 'error',
      title: 'Image Upload Failed',
      text: `${errorMessage}. Please try again.`,
    });
    
    console.error('Detailed error:', error); // Log the error for further debugging
    
    return [];
  }
  
};

  // Handler to update the opening hours
  const handleHoursChange = (updatedHours: Hour[]) => {
    setOpeningHours(updatedHours);
  };



  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      const gymGroupId = location.state?.gymGroupId;
      e.preventDefault();
      if (!isFormValid) {
        return; // Don't submit if there are validation errors
      }
  
      // Check if images are not uploaded
      if (formData.logos.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Please upload images',
          text: 'You need to upload at least one image to proceed.',
        });
        return; // Stop submission
      }
  
      // Start loading spinner
      setLoading(true);
  
      try {
        let response;
        if (gymId) {
          // Initialize `cleanedPresignedUrls` for use throughout the function
          let cleanedPresignedUrls: string[] = [];
        
          // Filter images that are not logos
          const filteredLogos = formData.logos.filter((file) => !file.name.startsWith('logo'));
        
          if (filteredLogos.length > 0) {
            // Get the extensions of the non-logo images
            const extensions = filteredLogos.map((file) => {
              const fileParts = file.name.split('.');
              return fileParts.length > 1 ? fileParts[fileParts.length - 1].toLowerCase() : '';
            });
        
            // Define gym name
            const branchName = formData.branchName;
        
            // Get presigned URLs for the images that are not logos
            const presignedUrls: string[] = await getPresignedUrlforBranch(gymGroupId, branchName, extensions);
        
            // Clean up the presigned URLs by removing the 'data:' prefix if present
            cleanedPresignedUrls = cleanPresignedUrls(presignedUrls);
            console.log('Cleaned Presigned URLs:', cleanedPresignedUrls);
        
            // Check if we received the presigned URLs
            if (!presignedUrls || presignedUrls.length === 0) {
              Swal.fire({
                icon: 'error',
                title: 'Failed to get presigned URLs',
                text: 'Please try again later.',
              });
              setLoading(false);
              return; // Stop submission
            }
        
            // Upload non-logo images using the presigned URLs
            const uploadedImageUrls = await uploadImages(filteredLogos, cleanedPresignedUrls);
            if (uploadedImageUrls.length === 0) {
              setLoading(false);
              // If no URLs were returned, stop submission
              return;
            }
          }
        
          // Proceed with the rest of your function logic
          // Get the Keycloak user ID from the tokenParsed object
          const userId = keycloak.tokenParsed?.sub;
        
          if (!userId) {
            Swal.fire({
              icon: 'error',
              title: 'User ID not found',
              text: 'Unable to retrieve user information. Please try again.',
            });
            setLoading(false);
            return;
          }
        
          // Map openingHours to GymHoursCreateDTO[]
          const gymHoursCreateDTOList = openingHours.map((hour) => ({
            day: hour.day,
            openTime: hour.startTime,
            closeTime: hour.endTime,
            offDay: hour.isOpen,
            createdBy: userId,
            updatedBy: "",
          }));
        
          // Update the gymData with the uploaded image URLs
          const updatedFormData = {
            ...formData,
            createdBy: userId,
          };
        
          // Update gym if gymId exists
          response = await updateGymBranch(
            gymId,
            {
              gymName: updatedFormData.branchName,
              gymType: updatedFormData.gymtype,
              accountHolderName: updatedFormData.holderName,
              accountNumber: updatedFormData.accountNumber,
              bankName: updatedFormData.bankName,
              branchBankName: updatedFormData.branchBankName,
              swiftCode: updatedFormData.swiftCode,
              latitude: updatedFormData.latitude ?? 0,
              longitude: updatedFormData.longitude ?? 0,
              location: updatedFormData.location,
              createdBy: userId,
              gymHoursCreateDTOList: gymHoursCreateDTOList,
              description: updatedFormData.description,
              number: updatedFormData.number,
              street: updatedFormData.street,
              city: updatedFormData.city,
              state: updatedFormData.state,
              country: updatedFormData.country,
              postalCode: updatedFormData.postalCode
            }
          );
        
          if (response && response.id) {
            // Call the updateGymGroupImages function
            const gymBranchImageUpdate = {
              gymImageDTO: {
                gymGroupId: gymGroupId,
                gymId: gymId,
                imageUrls: cleanedPresignedUrls, // Now `cleanedPresignedUrls` is available here
                createdBy: updatedFormData.createdBy,
                updatedBy: updatedFormData.createdBy,
              },
              imagesIdList: removedImageIds.map(id => Number(id)), // Provide a list of image IDs if necessary
            };
        
            await updateGymBranchImages(gymBranchImageUpdate);
        
            Swal.fire({
              icon: 'success',
              title: 'Gym updated successfully',
              text: `Your gym has been successfully updated.`,
            });
            setLoading(false);
            setOpeningHours([]);
            navigate('/gym-branch', { state: { gymId: gymGroupId} });
            setSuccess(true);
            setTimeout(() => setSuccess(false), 3000);
          }
        }        
         else {
             // Get the extensions of the selected images
            const extensions = formData.logos.map((file) => {
              const fileParts = file.name.split('.');
              return fileParts[fileParts.length - 1].toLowerCase();
            });
        
            // Define gym name
            const branchName = formData.branchName;
        
            // Get presigned URLs for the images
            const presignedUrls: string[] = await getPresignedUrlforBranch(gymGroupId, branchName, extensions);
        
            // Clean up the presigned URLs by removing the 'data:' prefix if present
            const cleanedPresignedUrls = cleanPresignedUrls(presignedUrls);
            console.log('Cleaned Presigned URLs:', cleanedPresignedUrls);
        
            // Check if we received the presigned URLs
            if (!presignedUrls || presignedUrls.length === 0) {
              Swal.fire({
                icon: 'error',
                title: 'Failed to get presigned URLs',
                text: 'Please try again later.',
              });
              setLoading(false);
              return; // Stop submission
            }
        
            // Upload images using the presigned URLs
            const uploadedImageUrls = await uploadImages(formData.logos, cleanedPresignedUrls);
            if (uploadedImageUrls.length === 0) {
              setLoading(false);
              // If no URLs were returned, stop submission
              return;
            }
        
            // Get the Keycloak user ID from the tokenParsed object
            const userId = keycloak.tokenParsed?.sub;
        
            if (!userId) {
              Swal.fire({
                icon: 'error',
                title: 'User ID not found',
                text: 'Unable to retrieve user information. Please try again.',
              });
              setLoading(false);
              return;
            }
        
            // Map openingHours to GymHoursCreateDTO[]
            const gymHoursCreateDTOList = openingHours.map((hour) => ({
              day: hour.day,
              openTime: hour.startTime,
              closeTime: hour.endTime,
              offDay: hour.isOpen,
              createdBy: userId,
              updatedBy: "",
            }));
        
            // Update the gymData with the uploaded image URLs
            const updatedFormData = {
              ...formData,
              createdBy: userId,
            };
          // Create gym if gymId does not exist
          response = await createGymBranch(
            {
              gymName: updatedFormData.branchName,
              gymType: updatedFormData.gymtype,
              accountHolderName: updatedFormData.holderName,
              accountNumber: updatedFormData.accountNumber,
              bankName: updatedFormData.bankName,
              branchBankName: updatedFormData.branchBankName,
              swiftCode: updatedFormData.swiftCode,
              latitude: updatedFormData.latitude ?? 0,
              longitude: updatedFormData.longitude ?? 0,
              location: updatedFormData.location,
              createdBy: updatedFormData.createdBy,
              gymHoursCreateDTOList: gymHoursCreateDTOList,
              description: updatedFormData.description,
              number: updatedFormData.number,
              street: updatedFormData.street,
              city: updatedFormData.city,
              state: updatedFormData.state,
              country: updatedFormData.country,
              postalCode: updatedFormData.postalCode
            },
            gymGroupId
          );
          if (response && response.id) {
            const gymGroupImages = {
              gymGroupId: gymGroupId,
              gymId: response.id,
              imageUrls: cleanedPresignedUrls,
              createdBy: updatedFormData.createdBy,
              updatedBy: "",
            };
    
            await saveGymBranchImages(gymGroupImages);
    
            Swal.fire({
              icon: 'success',
              title:'Gym created successfully',
              text: `Your gym has been successfully created'}.`,
            });
        }
          setLoading(false);
          setOpeningHours([]);
          navigate('/gym-branch', { state: { gymId: gymGroupId} });
          setSuccess(true);
          setTimeout(() => setSuccess(false), 3000);
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error ${gymId ? 'updating' : 'creating'} gym`,
          text: `An error occurred while ${gymId ? 'updating' : 'creating'} the gym. Please try again.`,
        });
        setLoading(false);
      }
    },
    [isFormValid, formData, gymId, openingHours]
  );
  

  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <form className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-md" onSubmit={handleSubmit}>
        <h2 className="text-lg font-semibold mb-6">Branch Information</h2>
        {/* Branch Name and Gym Type */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label htmlFor="branchName" className="mb-2 font-medium">
              Branch Name<span className="text-red-500">*</span>
            </label>
            <input
                type="text"
                name="branchName"
                id="branchName"
                value={formData.branchName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter branch name"
                required
            />
            {errors.branchName && touched.branchName && (
                <span className="text-red-500 text-sm">{errors.branchName}</span>
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="gymtype" className="mb-2 font-medium">
              Gym Type<span className="text-red-500">*</span>
            </label>
            <input
                type="text"
                name="gymtype"
                id="gymtype"
                value={formData.gymtype}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`p-2 border ${
                    errors.gymtype && touched.gymtype ? "border-red-500" : "border-gray-300"
                } rounded-md`}
                placeholder="Enter gym type"
                required
            />
            {errors.gymtype && touched.gymtype && (
                <span className="text-red-500 text-sm">{errors.gymtype}</span>
            )}
          </div>
        </div>

        {/* Description Field */}
        <div className="grid grid-cols-1 mt-4">
          <div className="flex flex-col">
            <label htmlFor="description" className="mb-2 font-medium">
              Description (Optional)
            </label>
            <textarea
                name="description"
                id="description"
                value={formData.description}
                onChange={handleChange}
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter description"
                rows={4}
            ></textarea>
          </div>
        </div>

        {/* Address Fields */}
        <h2 className="text-lg font-semibold mt-8 mb-6">Address Information</h2>
        <form className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex flex-col">
            <label htmlFor="number" className="mb-2 font-medium">
              Number
            </label>
            <input
                type="text"
                name="number"
                id="number"
                value={formData.number}
                onChange={handleChange}
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter number"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="street" className="mb-2 font-medium">
              Street
            </label>
            <input
                type="text"
                name="street"
                id="street"
                value={formData.street}
                onChange={handleChange}
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter street"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="city" className="mb-2 font-medium">
              City
            </label>
            <input
                type="text"
                name="city"
                id="city"
                value={formData.city}
                onChange={handleChange}
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter city"
            />
          </div>
          {/*<div className="text-lg font-semibold mt-8 mb-6 col-6">*/}
          {/*  <div className="flex flex-col">*/}
          {/*    <label htmlFor="state" className="mb-2 font-medium">*/}
          {/*      State*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*        type="text"*/}
          {/*        name="state"*/}
          {/*        id="state"*/}
          {/*        value={formData.state}*/}
          {/*        onChange={handleChange}*/}
          {/*        className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"*/}
          {/*        placeholder="Enter state"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="flex flex-col">*/}
          {/*    <label htmlFor="country" className="mb-2 font-medium">*/}
          {/*      Country*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*        type="text"*/}
          {/*        name="country"*/}
          {/*        id="country"*/}
          {/*        value={formData.country}*/}
          {/*        onChange={handleChange}*/}
          {/*        className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"*/}
          {/*        placeholder="Enter country"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="flex flex-col">*/}
          {/*    <label htmlFor="postalCode" className="mb-2 font-medium">*/}
          {/*      Postal Code*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*        type="text"*/}
          {/*        name="postalCode"*/}
          {/*        id="postalCode"*/}
          {/*        value={formData.postalCode}*/}
          {/*        onChange={handleChange}*/}
          {/*        className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"*/}
          {/*        placeholder="Enter postal code"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

        </form>

        <div>
          {/* Location Input */}
          <div className="flex flex-col mt-6">
            <label htmlFor="location" className="mb-2 font-medium">
              Select Location<span className="text-red-500">*</span>
            </label>
            <input
                ref={autocompleteRef} // Attach ref for Autocomplete
                type="text"
                name="location"
                id="location"
                value={formData.location}
                onChange={handleChange}
                onBlur={handleBlur} // Track when the field is blurred
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter city name"
                required
            />
            {errors.location && touched.location && (
                <span className="text-red-500 text-sm">{errors.location}</span>
            )}
          </div>

          {/* Display Coordinates */}
          {formData.latitude && formData.longitude && (
              <div className="mt-4" style={{display: 'none'}}>
                <p>Latitude: {formData.latitude}</p>
                <p>Longitude: {formData.longitude}</p>
              </div>
          )}

          {/* Map */}
          <div
              ref={mapRef} // Attach ref for the map
              style={{height: '400px', width: '100%', marginTop: '20px'}}
          ></div>
        </div>

        {/* Logos Upload */}
        <div className="flex flex-col mb-6">
          <label className="mb-2 font-medium">Images<span className="text-red-500">*</span></label>
          <div className="border border-dashed border-gray-300 p-4 rounded-lg text-center relative">
            <input
                ref={fileInputRef}
                type="file"
                name="logos"
                onChange={handleFileChange}
                className="hidden"
                multiple
            />
            <span
                className="text-gray-500 cursor-pointer"
                onClick={() => fileInputRef.current?.click()}
            >
              Click to upload images
            </span>
            {/* Display the uploaded images */}
            <div className="relative mt-4 grid grid-cols-6 gap-2">
              {formData.logos.slice(0, 6).map((file, index) => (
                  <div key={index} className="relative">
                    <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className="w-24 h-24 object-cover rounded-lg"
                    />
                    {/* Close button */}
                    <button
                        type="button"
                        aria-label="Remove image"
                        className="absolute top-0 left-1/2 transform -translate-x-1/2 bg-red-500 text-white rounded-full p-1"
                        onClick={() => handleRemoveImage(index)}
                    >
                      ✕
                    </button>
                  </div>
              ))}

              {/* Show "+ more" count if logos exceed 6 */}
              {formData.logos.length > 6 && (
                  <div
                      className="absolute bottom-2 right-2 bg-gray-700 text-white rounded-full px-3 py-1 text-xs cursor-pointer"
                      onClick={openModal}
                  >
                    +{formData.logos.length - 6} more
                  </div>
              )}
            </div>
          </div>
        </div>

        {/* Bank Details Section */}
        <h2 className="text-lg font-semibold mt-8 mb-6">Bank Details</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Holder Name */}
          <div className="flex flex-col">
            <label htmlFor="holderName" className="mb-2 font-medium">Holder Name<span className="text-red-500">*</span></label>
            <input
                type="text"
                name="holderName"
                id="holderName"
                value={formData.holderName}
                onChange={handleChange}
                onBlur={handleBlur} // Track when the field is blurred
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter holder name"
                required
            />
            {errors.holderName && touched.holderName &&
                <span className="text-red-500 text-sm">{errors.holderName}</span>}

          </div>

          {/* Account Number */}
          <div className="flex flex-col">
            <label htmlFor="accountNumber" className="mb-2 font-medium">Account Number<span
                className="text-red-500">*</span></label>
            <input
                type="text"
                name="accountNumber"
                id="accountNumber"
                value={formData.accountNumber}
                onChange={handleChange}
                onBlur={handleBlur} // Track when the field is blurred
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter account number"
                required
            />
            {errors.accountNumber && touched.accountNumber &&
                <span className="text-red-500 text-sm">{errors.accountNumber}</span>}

          </div>

          {/* Bank Name */}
          <div className="flex flex-col">
            <label htmlFor="bankName" className="mb-2 font-medium">Bank Name<span
                className="text-red-500">*</span></label>
            <input
                type="text"
                name="bankName"
                id="bankName"
                value={formData.bankName}
                onChange={handleChange}
                onBlur={handleBlur} // Track when the field is blurred
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter bank name"
                required
            />
            {errors.bankName && touched.bankName && <span className="text-red-500 text-sm">{errors.bankName}</span>}

          </div>

          {/* Branch Bank Name */}
          <div className="flex flex-col">
            <label htmlFor="branchBankName" className="mb-2 font-medium">Branch Name<span
                className="text-red-500">*</span></label>
            <input
                type="text"
                name="branchBankName"
                id="branchBankName"
                value={formData.branchBankName}
                onChange={handleChange}
                onBlur={handleBlur} // Track when the field is blurred
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter branch name"
                required
            />
            {errors.branchBankName && touched.branchBankName &&
                <span className="text-red-500 text-sm">{errors.branchBankName}</span>}

          </div>

          {/* SWIFT Code */}
          <div className="flex flex-col">
            <label htmlFor="swiftCode" className="mb-2 font-medium">SWIFT Code<span
                className="text-red-500">*</span></label>
            <input
                type="text"
                name="swiftCode"
                id="swiftCode"
                value={formData.swiftCode}
                onChange={handleChange}
                onBlur={handleBlur} // Track when the field is blurred
                className="p-2 border rounded-md focus:ring-2 focus:ring-green-500"
                placeholder="Enter SWIFT code"
                required
            />
            {errors.swiftCode && touched.swiftCode && <span className="text-red-500 text-sm">{errors.swiftCode}</span>}

          </div>

        </div>

        <div className="w-full">
          <div>
            <OpeningHours hours={openingHours} onHoursChange={handleHoursChange}/>
          </div>
        </div>

        {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-400">
              <Spinner/>
            </div>
        )}

        {/* Submit Button */}
        <button
            type="submit"
            className="mt-6 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600"
            disabled={!isFormValid}
        >
          {gymId ? "Update" : "Submit"}
        </button>
        <button
            type="button"
            className="bg-red-500 text-white ml-2 px-4 py-2 rounded-lg hover:bg-red-600"
            onClick={handleCancel}
        >
          Cancel
        </button>
      </form>

      {/* Modal */}
      {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg relative">
              {/* Close Button */}
              <button
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 z-50"
                  onClick={closeModal}
              >
                ✕
              </button>

              {/* Image with Delete Button Positioned Below the Center */}
              <div className="relative flex flex-col items-center">
                <img
                    src={URL.createObjectURL(formData.logos[currentImageIndex])}
                    alt={`Image ${currentImageIndex + 1}`}
                    className="max-w-xs max-h-80 object-cover"
                />

                {/* Delete Button Positioned Outside the Image */}
                <button
                    className="bg-gray-700 bg-opacity-50 rounded-full p-2 text-white mt-2"
                    onClick={() => handleRemoveImage(currentImageIndex)}
                >
                  🗑️
                </button>
              </div>

              <div className="flex justify-between items-center mt-4">
                {/* Previous Button */}
                <button
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                    onClick={handlePrevImage}
                    disabled={currentImageIndex === 0}
                >
                  Previous
                </button>

                {/* Image Count Display */}
                <span className="text-gray-700 font-medium">
          {currentImageIndex + 1} of {formData.logos.length}
        </span>

        {/* Next Button */}
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
          onClick={handleNextImage}
          disabled={currentImageIndex === formData.logos.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  </div>
)}



    </div>
  );
};

export default AddBranch;
