import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { faBell,faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import profilePicture from '../assets/profile.jpg';
import GymBranchCard from './GymBranchCard';
import './GymBranch.css';
import { getGymById } from '../services/gymBusinessService';
import Swal from 'sweetalert2';
import { getGymBranchImageById } from '../services/imageUploadService';
import TablePagination from '@mui/material/TablePagination';
import { deleteById } from '../services/gymBranchService';
import { getSubscriptionByGymId } from '../services/subscription';

interface GymBranch {
  id: number;
  images: string[];
  name: string;
  description: string;
  location: string;
}

interface Subscription {
  id: number;
  subscriptionName: any;
  price: number;
  duration: string;
  description: string;
}


const GymBranch: React.FC = () => {
  const navigate = useNavigate();
  const [gymbranches, setGyms] = useState<GymBranch[]>([]);
  const location = useLocation();
  const gymId = location.state?.gymId;
  const gymName = location.state?.gymName;
  const [totalSubscriptions, setTotalSubscriptions] = useState(0);
  const [gymBranches, setGymBranches] = useState<GymBranch[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [branchCount, setBranchCount] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(6);
  const [subscriptionsByBranch, setSubscriptionsByBranch] = useState<Record<string, number>>({});

  async function getCityFromGeoLocation(geoLocation: string | null | undefined): Promise<string> {
    if (!geoLocation || typeof geoLocation !== 'string') return 'Location not available';
    
    const regex = /POINT \(([^ ]+) ([^ ]+)\)/;
    const match = geoLocation.match(regex);
    if (!match) return 'Location not available';
  
    const [_, latitude, longitude] = match;
  
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDTXCGzDQ5zCqQGV3adPFv-MXxbvNXvr2k`
      );
      const data = await response.json();
  
      if (data.status === 'OK') {
        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find((component: any) =>
          component.types.includes('locality')
        );
  
        return cityComponent ? cityComponent.long_name : 'City not found';
      } else {
        return 'City not found';
      }
    } catch (error) {
      console.error('Error fetching city:', error);
      return 'Location not available';
    }
  }

// Fetch gym branches
useEffect(() => {
  if (gymId) {
    getGymById(gymId)
      .then(async (data: any) => {
        const branches: GymBranch[] = await Promise.all(
          data.gyms.map(async (gym: any) => {
            let imageUrls: string[] = [];
            try {
              const imageResponse = await getGymBranchImageById(gym.id);
              const uniqueImageUrls = Array.from(new Set(imageResponse));
              imageUrls = uniqueImageUrls;
            } catch (imageError) {
              console.error(`Error fetching images for gym ${gym.id}:`, imageError);
            }

            // Ensure geoLocation is a string
            const geoLocation = typeof gym.geoLocation === 'string' ? gym.geoLocation : '';

            return {
              id: gym.id,
              images: imageUrls,
              name: gym.gymName,
              description: gym.gymType,
              location: await getCityFromGeoLocation(geoLocation),
            };
          })
        );

        setGymBranches(branches);
        setBranchCount(branches.length);
      })
      .catch((error: any) => {
        console.error("Error fetching gym branches:", error);
      })
      .finally(() => setLoading(false));
  } else {
    setLoading(false);
  }
}, [gymId]);

// Fetch subscriptions for each branch
useEffect(() => {
  if (gymBranches.length > 0) {
    const fetchSubscriptions = async () => {
      const subscriptions: Record<string, number> = {};
      let totalSubscriptions = 0; // Initialize a counter for total subscriptions

      for (const branch of gymBranches) {
        try {
          const data = await getSubscriptionByGymId(branch.id);
          console.log({data})
          const length = (data as Subscription[]).length;
          subscriptions[branch.id] = length; // Store branch-specific subscription length
          totalSubscriptions += length; // Increment the total subscription count
        } catch (error) {
          console.error(`Error fetching subscriptions for gym ${branch.id}:`, error);
        }
      }

      setSubscriptionsByBranch(subscriptions);
      setTotalSubscriptions(totalSubscriptions); // Store the total length in state
    };

    fetchSubscriptions();
  }
}, [gymBranches]);



  const handleDelete = async (id: number) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be undone. Do you want to proceed?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });
  
    if (result.isConfirmed) {
      try {
        // Call the delete service
        await deleteById(id); 
  
        // Filter out the deleted gym branch from the state
        setGymBranches(prevBranches => prevBranches.filter(branch => branch.id !== id));
  
        // Show a success alert
        Swal.fire('Deleted!', 'Gym branch has been deleted successfully.', 'success');
      } catch (error) {
        console.error('Error deleting gym branch:', error);
        setError('Failed to delete gym branch. Please try again later.');
      }
    }
  };
  

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedGymBranches = gymBranches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <div className="header">
      <FontAwesomeIcon icon={faArrowLeft} className="icon-arrow-left" onClick={() => navigate(-1)} />
      <input style={{ width: '650px'}} type="text" placeholder="Search" className="search-bar" />
        <div className="header-right">
          <button className="add-gym-button" onClick={() => navigate('/add-branch', { state: { gymGroupId: gymId } })}>
            ADD BRANCH
          </button>
          <FontAwesomeIcon icon={faBell} className="icon-bell" />
          <img src={profilePicture} alt="Profile" className="profile-picture" />
        </div>
      </div>

      <div className="gym-info">
        <h2 className="gym-title">{gymName} GYM</h2>
        <div className="gym-cards">
          <div className="card">
            <h3>{branchCount}</h3>
            <p>Branches</p>
          </div>
          <div className="card">
            <h3>{totalSubscriptions}</h3>
            <p>Available Plans</p>
          </div>
          <div className="card">
            <h3>10</h3>
            <p>Total Revenue</p>
          </div>
          <div className="card">
            <h3>10 : 5</h3>
            <p>Gender Ratio (M : F)</p>
          </div>
        </div>
      </div>

      <div className="gym-cards-container">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : paginatedGymBranches.length > 0 ? (
          paginatedGymBranches.map((gym) => (
            <GymBranchCard 
              key={gym.id}
              gymBranch={gym}
              onDelete={handleDelete}
              gymGroupId={gymId} 
            />
          ))
        ) : (
          <p>No gym branches found.</p>
        )}
      </div>

      <TablePagination
        component="div"
        count={gymBranches.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[6, 12, 24]}
      />
    </div>
  );
};

export default GymBranch;
